import { Component } from "react"

class MoneyTransferSVG extends Component {
    render() {
        return (
            <svg version="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" enableBackground="new 0 0 48 48">
                <path fill="#78909C" d="M40,41H8c-2.2,0-4-1.8-4-4l0-20.9c0-1.3,0.6-2.5,1.7-3.3L24,0l18.3,12.8c1.1,0.7,1.7,2,1.7,3.3V37 C44,39.2,42.2,41,40,41z" />
                <rect x="14" y="1" fill="#AED581" width="20" height="31" />
                <g fill="#558B2F">
                    <path d="M13,0v33h22V0H13z M33,31H15V2h18V31z" />
                    <path d="M34,3c0,1.7-0.3,3-2,3c-1.7,0-3-1.3-3-3s1.3-2,3-2C33.7,1,34,1.3,34,3z" />
                    <path d="M16,1c1.7,0,3,0.3,3,2s-1.3,3-3,3s-2-1.3-2-3S14.3,1,16,1z" />
                    <circle cx="24" cy="8" r="2" />
                    <circle cx="24" cy="20" r="6" />
                </g>
                <path fill="#CFD8DC" d="M40,41H8c-2.2,0-4-1.8-4-4l0-20l20,13l20-13v20C44,39.2,42.2,41,40,41z" />
            </svg>
        )
    }
}

export default MoneyTransferSVG