import { Component } from "react"

class FeedbackSVG extends Component {
    render() {
        return (
            <svg version="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" enableBackground="new 0 0 48 48">
                <path fill="#78909C" d="M40,41H8c-2.2,0-4-1.8-4-4l0-20.9c0-1.3,0.6-2.5,1.7-3.3L24,0l18.3,12.8c1.1,0.7,1.7,2,1.7,3.3V37 C44,39.2,42.2,41,40,41z" />
                <rect x="12" y="11" fill="#fff" width="24" height="22" />
                <polygon fill="#9C27B0" points="24,13.6 18,21.4 30,21.4" />
                <path fill="#CFD8DC" d="M40,41H8c-2.2,0-4-1.8-4-4l0-20l20,13l20-13v20C44,39.2,42.2,41,40,41z" />
                <polygon fill="#9C27B0" points="24,28 26,26.7 26,20 22,20 22,26.7" />
            </svg>
        )
    }
}

export default FeedbackSVG